import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Layout from './components/Layout/layout'
import Home from './containers/HomeContainer/Home'
import Horoscope from './containers/AstrologyContainer/Horoscope'
import Tarot from './containers/AstrologyContainer/Tarot'
import Economy from './containers/EconomicsContainer/Economics'
import Login from './containers/LoginContainer/Login'

function App() {

  const isLogged = document.cookie.includes('token=')
  
  return (
    <BrowserRouter>
      <div>
        <Layout>
          <Routes>
            <Route path="/" exact element={!isLogged ? <Navigate to='/login' /> : <Home />} />
            <Route path="/login" element={!isLogged ? <Login /> : <Home />} />
            <Route path="/economia" element={!isLogged ? <Navigate to='/login' /> : <Economy />} />
            <Route path="/horoscopo" element={!isLogged ? <Navigate to='/login' /> : <Horoscope />} />
            <Route path="/tarot" element={!isLogged ? <Navigate to='/login' /> : <Tarot />} />
          </Routes>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default App;
