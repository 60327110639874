const sections = {
	Horoscope: {
		link: '/horoscopo',
		name: 'Horóscopo',
		description: 'Formulario que permite enviar los datos de la sección Horóscopo hacia la plataforma del Espectador'
	},
	Tarot: {
		link: '/tarot',
		name: 'Tarot',
		description: 'Formulario que permite enviar los datos de la sección Tarot hacia la plataforma del Espectador'
	},
	Economics: {
		link: '/economia',
		name: 'Datos económicos',
		description: 'Formulario que permite enviar los datos economicos del día para ser vistos en el portal del Espectador'
	}
}

export const defineRole = ({role}) => {
    let sectionsObj = {}
    switch (role) {
        case 'admin':
            sectionsObj = {...sections}
            break;
        case 'tarot':
            sectionsObj = {Tarot: {...sections.Tarot}, Horoscope: {...sections.Horoscope}}
            break
        case 'economy':
            sectionsObj = {Economics: {...sections.Economics}}
            break
        default:
            sectionsObj = {}
            break;
    }
    return sectionsObj
}